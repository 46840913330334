@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TheJamsil5Bold';
  src: url('./fonts/The Jamsil OTF 5 Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'TheJamsil5Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 1080px;
  aspect-ratio: 9/16;
}

@media screen and (min-width: 100vh) {
  #root {
    width: 1920px;
    aspect-ratio: 16/9;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-text {
  background: var(--GMOHOB2, linear-gradient(135deg, #E3FAFF 0%, #53CAF5 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-bg {
  background: var(--GMOHOB2, linear-gradient(135deg, #E3FAFF 0%, #53CAF5 100%));
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: url(#gradient) !important;
}