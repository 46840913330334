.blinking {
    animation: blink 2s ease-in-out infinite alternate;
}

@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    95% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes blink {
    0% {
            opacity: 1;
        }
    
        90% {
            opacity: 1;
        }
    
        95% {
            opacity: 0.6;
        }

    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
            opacity: 1;
        }
    
        90% {
            opacity: 1;
        }
    
        95% {
            opacity: 0.6;
        }

    100% {
        opacity: 1;
    }
}